import {
  StoryblokComponent,
  getStoryblokApi,
  useStoryblokState,
} from '@storyblok/react'

import { getStaticPageRevalidationInterval } from '../modules/core/utils/env'
import { Head } from '../modules/layout/components/head'
import { PageLayout } from '../modules/layout/components/page-layout'
import { isStoryblokFooter } from '../modules/storyblok/components/footer/Footer.types'
import { isStoryblokNavigation } from '../modules/storyblok/components/navigation'
import { TranslationsProvider } from '../modules/storyblok/context/translations-provider'

import type { ISbStoryParams, ISbStoryData } from '@storyblok/react'
import type { GetStaticProps } from 'next'
import type { StoryblokDatasourceEntry } from 'types'

type NotFoundProps = {
  story: ISbStoryData
  translations: StoryblokDatasourceEntry[]
  navigationStory?: ISbStoryData
  footerStory?: ISbStoryData
  locale?: string
  locales?: string[]
  key: number
}

export default function NotFound({
  story: initialStory,
  translations,
  navigationStory,
  footerStory,
  locale,
  locales,
}: NotFoundProps) {
  const story = useStoryblokState(initialStory)
  const { seo_metatags } = story?.content || {}

  return (
    <TranslationsProvider locale={locale ?? 'nl'} translations={translations}>
      {story && <Head {...{ story, seo_metatags }} />}
      <PageLayout
        navigation={
          navigationStory?.content &&
          isStoryblokNavigation(navigationStory.content)
            ? navigationStory.content
            : undefined
        }
        footer={
          footerStory?.content && isStoryblokFooter(footerStory.content)
            ? footerStory.content
            : undefined
        }
        locale={locale}
        locales={locales}
      >
        <StoryblokComponent blok={story?.content} locale={locale} />
      </PageLayout>
    </TranslationsProvider>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale, locales }) => {
  const storyParameters: ISbStoryParams = {
    version: process.env.NODE_ENV === 'development' ? 'draft' : 'published',
    language: locale,
  }

  const storyblokApi = getStoryblokApi()

  const { data } = await storyblokApi.get(
    `cdn/stories/not-found`,
    storyParameters
  )

  const { data: navigationData } = await storyblokApi.get(
    `cdn/stories/configuration/navigation`,
    storyParameters
  )
  const { data: translationsData } = await storyblokApi.get(
    'cdn/datasource_entries',
    { dimension: locale, per_page: 1000 }
  )
  const { data: footerData } = await storyblokApi.get(
    `cdn/stories/configuration/footer`,
    storyParameters
  )

  return {
    props: {
      story: data ? data.story : false,
      translations: translationsData.datasource_entries,
      key: data ? data.story.id : false,
      navigationStory: navigationData.story,
      footerStory: footerData.story,
      locale,
      locales,
    },
    revalidate: getStaticPageRevalidationInterval(),
  }
}
